import './SecurityHome.css';
import React, {useState} from "react";
import { Link } from 'react-router-dom'

export default function SecurityHome() {
  return (
    <div className="Page">
        <section className = "Title">
          Introduction to Cybersecurity
        </section>
        <section className = "Text">
          Under construction! Please be patient. 
          <p></p>
          These pages are designed to help secondary-school age students get interested in Cybersecurity. However, anyone can use it and I hope it is helpful to all. 
          <p></p>
          Be aware that cyber attacks are not a game, and can have <Link to="https://en.wikipedia.org/wiki/Morris_worm">real world consequences</Link>. Please keep all attacks confined to your own systems. 
        </section>
    </div>
  );
}