import './Projects.css';
import React, {useState} from "react";

export default function ProjectsHome() {
  return (
    <div className="Page">
        <section className = "Title">
          Projects
        </section>
        <section className = "Text">
          This is a placeholder, it will hold the various projects I am working on! 
          <p>
          You can look forward to 'A Hat Full of Sky' –  a top hat with fibre optic "stars". 
          </p>
        </section>
    </div>
  );
}